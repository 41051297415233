import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "12d1d2ae-12ac-4807-80de-b881dd2002bc"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage12d1d2ae12ac480780deB881dd2002bc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage12d1d2ae12ac480780deB881dd2002bc gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="HeaderF6bc0e015c74487a842e51e80035ff07" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5f3e0bcf69174a478193Fefe91ae2fb9" {...props} />}
    </div>
  );
}

// id: "e2fd3081-e1d7-4248-927f-f750a76c93a6"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyE2fd3081E1d74248927fF750a76c93a6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyE2fd3081E1d74248927fF750a76c93a6 page-body`}>
      {props["marketingCards"] || <MarketingCards97f8937cBbd840cd8552Bbe21cc9dda1 parentTag="MarketingCards48399032C0cd4370A1b7B6e8580e6f01" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "bc839488-be6b-4767-aaba-88e1894fcd5c"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageBc839488Be6b4767Aaba88e1894fcd5c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageBc839488Be6b4767Aaba88e1894fcd5c gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header156efa56311b4e40823a71d33cf9ddda" title="Public Events" {...props} />}
      <main className="main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer1fcb4a0902e8415cB7c48a1dea93f6e1" {...props} />}
    </div>
  );
}

// id: "f989cb61-8862-44de-b7fe-a2206d508aa0"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderF989cb61886244deB7feA2206d508aa0(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderF989cb61886244deB7feA2206d508aa0 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo635d2659Bfa44632A92215b87cc799ba", parentTag)} label={<DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="Label0905297a55c44ccaAb4598c886d14daf" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation076f4cffBcb54f2eB9fbF7ff8d4889e2", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "61f0ea49-a401-4ef5-9545-ee7127b9a2c8"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label61f0ea49A4014ef59545Ee7127b9a2c8(props: any) {
  return (
    <DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="Label61f0ea49A4014ef59545Ee7127b9a2c8" {...props} />
  );
}

// id: "02556be5-784d-4ee5-9ef2-04eff5d1753e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "7a54b720-6060-483b-b6c3-a1176b54d4f7"
export function HeaderTop02556be5784d4ee59ef204eff5d1753e(props: any) {
  return (
    <DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop02556be5784d4ee59ef204eff5d1753e" {...props} />
  );
}

// id: "55093b2a-56de-4cbb-9df4-a62074cd739c"
// title: ""
// type: :reference
// key: "body"
// parent_id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
export function Body55093b2a56de4cbb9df4A62074cd739c(props: any) {
  return (
    <MarketingBodyE2fd3081E1d74248927fF750a76c93a6 parentTag="Body55093b2a56de4cbb9df4A62074cd739c" {...props} />
  );
}

// id: "a86ad127-f21b-49d7-a708-f7257c716c99"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageA86ad127F21b49d7A708F7257c716c99(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageA86ad127F21b49d7A708F7257c716c99`}>
      {props["header"] || <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header0df4ae1880954c17B5928eab44704b24" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="FooterBaaf3cf17e284a80909c9f872e536896" {...props} />}
    </div>
  );
}

// id: "17b52c50-dc68-459c-b02b-bb45d71edeb9"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage17b52c50Dc68459cB02bBb45d71edeb9(props: any) {
  return (
    <SecondaryPage12d1d2ae12ac480780deB881dd2002bc parentTag="JoinUsPage17b52c50Dc68459cB02bBb45d71edeb9" header={<SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="HeaderDb5f228269f34efb9783978cde84f0f3" title="Join Us" />} {...props} />
  );
}

// id: "ff9450b8-886c-4853-83a1-bdbfa4004782"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleFf9450b8886c485383a1Bdbfa4004782 = "Leveling";

// id: "9009853a-498b-42c7-ba82-2607088e4eaf"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "f6913432-16f2-4da3-a078-d6f6b15fe0bf"
export function Navigation9009853a498b42c7Ba822607088e4eaf(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation9009853a498b42c7Ba822607088e4eaf", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "5f3e0bcf-6917-4a47-8193-fefe91ae2fb9"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "12d1d2ae-12ac-4807-80de-b881dd2002bc"
export function Footer5f3e0bcf69174a478193Fefe91ae2fb9(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5f3e0bcf69174a478193Fefe91ae2fb9" {...props} />
  );
}

// id: "78ffbbc8-0e61-4910-a480-0542d4a5faa9"
// title: ""
// type: :text
// key: "title"
// parent_id: "1f041276-f7a2-4419-8a11-bcf854c413db"
export const Title78ffbbc80e614910A4800542d4a5faa9 = "Default Title";

// id: "5f5fce32-aed3-4188-9832-46c9f833e7ae"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function SocialLinks5f5fce32Aed34188983246c9f833e7ae(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks5f5fce32Aed34188983246c9f833e7ae flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "0ff04668-1889-434b-925a-0ba649a3d2be"
// title: ""
// type: :text
// key: "title"
// parent_id: "8ff2fc22-5914-4ed4-883d-892703712b7d"
export const Title0ff046681889434b925a0ba649a3d2be = "Resources";

// id: "f7e76a50-5843-4097-9f89-2fcf3cb7d43e"
// title: ""
// type: :text
// key: "title"
// parent_id: "f6bc0e01-5c74-487a-842e-51e80035ff07"
export const TitleF7e76a50584340979f892fcf3cb7d43e = "Secondary Page";

// id: "444dd432-65c6-4a53-a6dc-ff399bcb8e60"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages444dd43265c64a53A6dcFf399bcb8e60(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages444dd43265c64a53A6dcFf399bcb8e60 gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "0ae297aa-6672-4db0-95a6-ceb9375a5d62"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage0ae297aa66724db095a6Ceb9375a5d62(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage0ae297aa66724db095a6Ceb9375a5d62 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header66b5c12a0e5745bd8e102483ca6987ab" title="About Us" {...props} />}
      {props["body"] || <Body1b8a3dc4D378469dAdcc39b73c3edf50 />}
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5d580719A5884d5dB4b22954ed84d50e" {...props} />}
    </div>
  );
}

// id: "48399032-c0cd-4370-a1b7-b6e8580e6f01"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "e2fd3081-e1d7-4248-927f-f750a76c93a6"
export function MarketingCards48399032C0cd4370A1b7B6e8580e6f01(props: any) {
  return (
    <MarketingCards97f8937cBbd840cd8552Bbe21cc9dda1 parentTag="MarketingCards48399032C0cd4370A1b7B6e8580e6f01" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7d2e6797-67b0-4282-9134-46f5f34a8c34"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault7d2e679767b04282913446f5f34a8c34(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault7d2e679767b04282913446f5f34a8c34 page`}>
      {props["header"] || <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header445420f0339b4a6985bf3e1a77760bc5" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="FooterE434e9599eb645f4B91cEbd5e359d3f6" {...props} />}
    </div>
  );
}

// id: "4e3f7e91-f99d-4c6c-873d-f241fc668fd3"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages4e3f7e91F99d4c6c873dF241fc668fd3(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages4e3f7e91F99d4c6c873dF241fc668fd3 gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header14322f0eB46f4fa2B977C1a4418e2d3d" {...props} />}
      <main className="main">
        {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavAef9bd54Cdd94aa0B3feBc5134eb84ee", parentTag)} {...props} />}
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5075cffa701f4e4c9cb2E7bed235bc7b" {...props} />}
    </div>
  );
}

// id: "f6913432-16f2-4da3-a078-d6f6b15fe0bf"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo6fba545b826a4fa9A4839890832b9ed1", parentTag)} label={<DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="LabelFe3fc269Ee21447884ddC123bcc97518" alt="logo" />} url="/" className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation9009853a498b42c7Ba822607088e4eaf", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "076f4cff-bcb5-4f2e-b9fb-f7ff8d4889e2"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "f989cb61-8862-44de-b7fe-a2206d508aa0"
export function Navigation076f4cffBcb54f2eB9fbF7ff8d4889e2(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation076f4cffBcb54f2eB9fbF7ff8d4889e2", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "64bb1d10-4721-4747-b869-0df2957b6242"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
export function WelcomeBack64bb1d1047214747B8690df2957b6242(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack64bb1d1047214747B8690df2957b6242", parentTag)} {...props} />
  );
}

// id: "15a314d2-3c76-467f-9e03-ebd49694b066"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "97f8937c-bbd8-40cd-8552-bbe21cc9dda1"
export const ButtonClass15a314d23c76467f9e03Ebd49694b066 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "156efa56-311b-4e40-823a-71d33cf9ddda"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bc839488-be6b-4767-aaba-88e1894fcd5c"
export function Header156efa56311b4e40823a71d33cf9ddda(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header156efa56311b4e40823a71d33cf9ddda" title="Public Events" {...props} />
  );
}

// id: "903bdc28-3767-4168-86fa-0eaf56a0b4f8"
// title: "Footer"
// type: :reference
// key: "footer"
// parent_id: "a1d59f01-95b8-4d75-ac36-85918fe2ae8e"
export function Footer903bdc283767416886fa0eaf56a0b4f8(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer903bdc283767416886fa0eaf56a0b4f8" {...props} />
  );
}

// id: "1a5332da-fa3d-483b-ae48-43b7502a115f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "9009853a-498b-42c7-ba82-2607088e4eaf"
export const NavMenuSlug1a5332daFa3d483bAe4843b7502a115f = "marketing-primary-nav";

// id: "752b4c1b-d6e1-41f1-8851-460ebe4c47ff"
// title: ""
// type: :text
// key: "label"
// parent_id: "186ebbf1-1d45-4ad7-ae4a-5d0f18d61901"
export const Label752b4c1bD6e141f18851460ebe4c47ff = "Join Us";

// id: "7f3fe285-67c3-4c10-9c38-f25c7e3d4059"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo7f3fe28567c34c109c38F25c7e3d4059(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" className={`${parentTag || ""} DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 logo`} alt="logo" />
  );
}

// id: "1dbf8443-76a8-459e-a9bd-eed0b1e880aa"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href1dbf844376a8459eA9bdEed0b1e880aa = "/";

// id: "6bf24987-0a43-43ff-b03d-887f6f6d17ff"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader6bf249870a4343ffB03d887f6f6d17ff(props: any) {
  return (
    <ShortHeaderF989cb61886244deB7feA2206d508aa0 parentTag="DefaultHeader6bf249870a4343ffB03d887f6f6d17ff" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "4d6a7730-07c1-404b-ba2d-46a9fcf81833"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer4d6a773007c1404bBa2d46a9fcf81833(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer4d6a773007c1404bBa2d46a9fcf81833" {...props} />
  );
}

// id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterDbad0bbeFe534b43A173E040f91e90c5(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 footer`}>
      {props["social-links"] || <SocialLinksFc775835179d4145A264F30222cc308a />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksCf613b45F9b24fd89ea94d44ea00635f", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoB5fcef0625e94476B0baD5c429dd0673 />}
      </div>
      <div className="copyright">
        2023 Mystique Entertainment and RDP Media Company
      </div>
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowE6622f55131c4d37Abe24ce2e5c7672b", parentTag)} {...props} />}
    </div>
  );
}

// id: "6fba545b-826a-4fa9-a483-9890832b9ed1"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "f6913432-16f2-4da3-a078-d6f6b15fe0bf"
export function LinkedLogo6fba545b826a4fa9A4839890832b9ed1(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo6fba545b826a4fa9A4839890832b9ed1", parentTag)} label={<DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="LabelFe3fc269Ee21447884ddC123bcc97518" alt="logo" />} url="/" className="linked-logo" {...props} />
  );
}

// id: "c64eeb18-40c5-450c-badf-dd9a73a0d143"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteC64eeb1840c5450cBadfDd9a73a0d143(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteC64eeb1840c5450cBadfDd9a73a0d143`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "fda27db5-187b-4540-908b-42004c9e9f47"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesFda27db5187b4540908b42004c9e9f47(props: any) {
  return (
    <SecondaryPage12d1d2ae12ac480780deB881dd2002bc parentTag="SignupPagesFda27db5187b4540908b42004c9e9f47" header={<SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header2b7acd427f874b83A3cbBe724959a86e" title="Join Us" />} {...props} />
  );
}

// id: "1f041276-f7a2-4419-8a11-bcf854c413db"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero1f041276F7a244198a11Bcf854c413db(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero1f041276F7a244198a11Bcf854c413db hero`}>
      <div className="title">
        {props["title"] || Title78ffbbc80e614910A4800542d4a5faa9}
      </div>
    </div>
  );
}

// id: "d5c011bf-4095-481a-9407-eab3739a03f9"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "7a54b720-6060-483b-b6c3-a1176b54d4f7"
export function HeroD5c011bf4095481a9407Eab3739a03f9(props: any) {
  return (
    <HomePageHeroE3d6b8406be74347B62cBbcb2d4cb0c4 parentTag="HeroD5c011bf4095481a9407Eab3739a03f9" {...props} />
  );
}

// id: "2b7acd42-7f87-4b83-a3cb-be724959a86e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fda27db5-187b-4540-908b-42004c9e9f47"
export function Header2b7acd427f874b83A3cbBe724959a86e(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header2b7acd427f874b83A3cbBe724959a86e" title="Join Us" {...props} />
  );
}

// id: "baaf3cf1-7e28-4a80-909c-9f872e536896"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a86ad127-f21b-49d7-a708-f7257c716c99"
export function FooterBaaf3cf17e284a80909c9f872e536896(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="FooterBaaf3cf17e284a80909c9f872e536896" {...props} />
  );
}

// id: "1fcb4a09-02e8-415c-b7c4-8a1dea93f6e1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "bc839488-be6b-4767-aaba-88e1894fcd5c"
export function Footer1fcb4a0902e8415cB7c48a1dea93f6e1(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer1fcb4a0902e8415cB7c48a1dea93f6e1" {...props} />
  );
}

// id: "ee5d2806-e539-47e0-ad72-9018d878788f"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "97f8937c-bbd8-40cd-8552-bbe21cc9dda1"
export const PostSlugEe5d2806E53947e0Ad729018d878788f = "home-page-posts2";

// id: "14322f0e-b46f-4fa2-b977-c1a4418e2d3d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4e3f7e91-f99d-4c6c-873d-f241fc668fd3"
export function Header14322f0eB46f4fa2B977C1a4418e2d3d(props: any) {
  return (
    <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header14322f0eB46f4fa2B977C1a4418e2d3d" {...props} />
  );
}

// id: "8d7e119e-7cc9-4339-9605-d6c6fabc1a3f"
// title: ""
// type: :text
// key: "title"
// parent_id: "6bf24987-0a43-43ff-b03d-887f6f6d17ff"
export const Title8d7e119e7cc943399605D6c6fabc1a3f = null;

// id: "674b01f7-6cc4-4fa9-b128-019b41352cc8"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "7f3fe285-67c3-4c10-9c38-f25c7e3d4059"
export const ImageUrl674b01f76cc44fa9B128019b41352cc8 = "https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png";

// id: "94c2b264-d12e-46be-87ae-2b73911b6ff3"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "c388d4e7-0fbb-4cf1-bacc-4b2c5aea06bc"
export function Hero94c2b264D12e46be87ae2b73911b6ff3(props: any) {
  return (
    <SecondaryMarketingHero1f041276F7a244198a11Bcf854c413db parentTag="Hero94c2b264D12e46be87ae2b73911b6ff3" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "db5f2282-69f3-4efb-9783-978cde84f0f3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "17b52c50-dc68-459c-b02b-bb45d71edeb9"
export function HeaderDb5f228269f34efb9783978cde84f0f3(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="HeaderDb5f228269f34efb9783978cde84f0f3" title="Join Us" {...props} />
  );
}

// id: "130ab71d-3874-47f9-a31c-40d9a3561481"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a1d59f01-95b8-4d75-ac36-85918fe2ae8e"
export function Header130ab71d387447f9A31c40d9a3561481(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header130ab71d387447f9A31c40d9a3561481" {...props} />
  );
}

// id: "51e11fee-ec96-498f-b070-9ee8e3300884"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "cf613b45-f9b2-4fd8-9ea9-4d44ea00635f"
export const NavMenuSlug51e11feeEc96498fB0709ee8e3300884 = "footer-nav";

// id: "fe3fc269-ee21-4478-84dd-c123bcc97518"
// title: ""
// type: :reference
// key: "label"
// parent_id: "6fba545b-826a-4fa9-a483-9890832b9ed1"
export function LabelFe3fc269Ee21447884ddC123bcc97518(props: any) {
  return (
    <DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="LabelFe3fc269Ee21447884ddC123bcc97518" {...props} />
  );
}

// id: "6c89ed81-9553-44ca-80e0-f52aaccb5b93"
// title: ""
// type: :text
// key: "title"
// parent_id: "db5f2282-69f3-4efb-9783-978cde84f0f3"
export const Title6c89ed81955344ca80e0F52aaccb5b93 = "Join Us";

// id: "705086d9-4fbc-4e54-a241-621dc14beec6"
// title: ""
// type: :text
// key: "title"
// parent_id: "66b5c12a-0e57-45bd-8e10-2483ca6987ab"
export const Title705086d94fbc4e54A241621dc14beec6 = "About Us";

// id: "20d55058-b9b0-4703-843f-20aaa7546293"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "445420f0-339b-4a69-85bf-3e1a77760bc5"
export const NavMenuSlug20d55058B9b04703843f20aaa7546293 = "members-primary-nav";

// id: "fb8eb8c9-b7d7-4911-9411-266eee43f7a2"
// title: ""
// type: :text
// key: "title"
// parent_id: "0df4ae18-8095-4c17-b592-8eab44704b24"
export const TitleFb8eb8c9B7d749119411266eee43f7a2 = "Leveling";

// id: "c0b26a17-e9a0-4ad3-a77b-c4ffb6198b0d"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d(props: any) {
  return (
    <ShortHeaderF989cb61886244deB7feA2206d508aa0 parentTag="MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d" header-top={<DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop8385fce07ad64f2d960801fedcd186dc" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "df14a7fd-5662-4b74-8a47-5000be1df259"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderDf14a7fd56624b748a475000be1df259(props: any) {
  return (
    <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="HeaderDf14a7fd56624b748a475000be1df259" {...props} />
  );
}

// id: "e3d6b840-6be7-4347-b62c-bbcb2d4cb0c4"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroE3d6b8406be74347B62cBbcb2d4cb0c4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroE3d6b8406be74347B62cBbcb2d4cb0c4 hero`}>
      <div className="title">
        Join a vibrant community.
        <br />
        Elevate your nightlife escapades to new heights!
      </div>
      <div className="buttons">
        <a href="/join" className="Button">
          Join Us
        </a>
        <a href="/events/public" className="Button">
          Events
        </a>
      </div>
    </div>
  );
}

// id: "8ff2fc22-5914-4ed4-883d-892703712b7d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6921be61-3d80-4b39-9820-85d488320c24"
export function Header8ff2fc2259144ed4883d892703712b7d(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header8ff2fc2259144ed4883d892703712b7d" title="Resources" {...props} />
  );
}

// id: "42d5a40e-d294-4f72-9cce-dc7a3d36da5c"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function Copyright42d5a40eD2944f729cceDc7a3d36da5c(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright42d5a40eD2944f729cceDc7a3d36da5c", parentTag)} {...props} />
  );
}

// id: "4d314121-deca-496e-a664-bb8309f6ad59"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
export function Header4d314121Deca496eA664Bb8309f6ad59(props: any) {
  return (
    <HomeHeader7a54b7206060483bB6c3A1176b54d4f7 parentTag="Header4d314121Deca496eA664Bb8309f6ad59" {...props} />
  );
}

// id: "5d2962a7-ca22-42c0-ac5b-e95f119ee2b7"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c388d4e7-0fbb-4cf1-bacc-4b2c5aea06bc"
export function HeaderTop5d2962a7Ca2242c0Ac5bE95f119ee2b7(props: any) {
  return (
    <DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop5d2962a7Ca2242c0Ac5bE95f119ee2b7" {...props} />
  );
}

// id: "0df4ae18-8095-4c17-b592-8eab44704b24"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a86ad127-f21b-49d7-a708-f7257c716c99"
export function Header0df4ae1880954c17B5928eab44704b24(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header0df4ae1880954c17B5928eab44704b24" title="Leveling" {...props} />
  );
}

// id: "1619de95-8c42-4ad8-ab0d-831e2d6cfa02"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
export function Footer1619de958c424ad8Ab0d831e2d6cfa02(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer1619de958c424ad8Ab0d831e2d6cfa02" {...props} />
  );
}

// id: "aef9bd54-cdd9-4aa0-b3fe-bc5134eb84ee"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "4e3f7e91-f99d-4c6c-873d-f241fc668fd3"
export function SecondaryNavAef9bd54Cdd94aa0B3feBc5134eb84ee(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavAef9bd54Cdd94aa0B3feBc5134eb84ee", parentTag)} {...props} />
  );
}

// id: "f6bc0e01-5c74-487a-842e-51e80035ff07"
// title: ""
// type: :reference
// key: "header"
// parent_id: "12d1d2ae-12ac-4807-80de-b881dd2002bc"
export function HeaderF6bc0e015c74487a842e51e80035ff07(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="HeaderF6bc0e015c74487a842e51e80035ff07" title="Secondary Page" {...props} />
  );
}

// id: "dbff28ca-8153-4326-a6c3-35b8f26cfcfa"
// title: ""
// type: :text
// key: "href"
// parent_id: "186ebbf1-1d45-4ad7-ae4a-5d0f18d61901"
export const HrefDbff28ca81534326A6c335b8f26cfcfa = "/join";

// id: "6b9893a5-d56d-4777-994c-cc0d4ba27956"
// title: ""
// type: :text
// key: "url"
// parent_id: "6fba545b-826a-4fa9-a483-9890832b9ed1"
export const Url6b9893a5D56d4777994cCc0d4ba27956 = "/";

// id: "03e8bd21-cce0-493a-8d14-84144b26f7bb"
// title: ""
// type: :text
// key: "title"
// parent_id: "156efa56-311b-4e40-823a-71d33cf9ddda"
export const Title03e8bd21Cce0493a8d1484144b26f7bb = "Public Events";

// id: "8bcc28d6-d01f-4a96-be32-11fcd92238e5"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "48399032-c0cd-4370-a1b7-b6e8580e6f01"
export const PostSlug8bcc28d6D01f4a96Be3211fcd92238e5 = "home-page-posts2";

// id: "6921be61-3d80-4b39-9820-85d488320c24"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage6921be613d804b39982085d488320c24(props: any) {
  return (
    <SecondaryPage12d1d2ae12ac480780deB881dd2002bc parentTag="ResourcesPage6921be613d804b39982085d488320c24" header={<SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header8ff2fc2259144ed4883d892703712b7d" title="Resources" />} {...props} />
  );
}

// id: "7de9c0e3-aa2e-4b76-aef4-90cb181874c2"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages7de9c0e3Aa2e4b76Aef490cb181874c2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages7de9c0e3Aa2e4b76Aef490cb181874c2 gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header1827118aE0d64a1cB979Fc0909372a22" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a1d59f01-95b8-4d75-ac36-85918fe2ae8e"
// title: "Contact"
// type: :html
// key: "contact"
// parent_id: nil
export function ContactA1d59f0195b84d75Ac3685918fe2ae8e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ContactA1d59f0195b84d75Ac3685918fe2ae8e gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header130ab71d387447f9A31c40d9a3561481" {...props} />}
      <main className="contact-main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer903bdc283767416886fa0eaf56a0b4f8" {...props} />}
    </div>
  );
}

// id: "c0ea1e7b-2eca-477b-b8ce-f585995e0549"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "7a54b720-6060-483b-b6c3-a1176b54d4f7"
export const BackgroundImageC0ea1e7b2eca477bB8ceF585995e0549 = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "cf613b45-f9b2-4fd8-9ea9-4d44ea00635f"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function LinksCf613b45F9b24fd89ea94d44ea00635f(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksCf613b45F9b24fd89ea94d44ea00635f", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "e6622f55-131c-4d37-abe2-4ce2e5c7672b"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function GroupflowE6622f55131c4d37Abe24ce2e5c7672b(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowE6622f55131c4d37Abe24ce2e5c7672b", parentTag)} {...props} />
  );
}

// id: "b5fcef06-25e9-4476-b0ba-d5c429dd0673"
// title: ""
// type: :html
// key: "logo"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function LogoB5fcef0625e94476B0baD5c429dd0673(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" alt="Mystique logo" className={`${parentTag || ""} LogoB5fcef0625e94476B0baD5c429dd0673 logo`} />
  );
}

// id: "b2c4b660-32f6-4637-bf46-ad39c2977625"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "1189e76f-03d7-48c2-bdd8-9cd9f6b84bbd"
export const ContentSlugB2c4b66032f64637Bf46Ad39c2977625 = "home-page-quote";

// id: "cc00be69-4a5d-4a17-800f-523efdfa1d5d"
// title: ""
// type: :text
// key: "class"
// parent_id: "9009853a-498b-42c7-ba82-2607088e4eaf"
export const ClassCc00be694a5d4a17800f523efdfa1d5d = "navigation";

// id: "1189e76f-03d7-48c2-bdd8-9cd9f6b84bbd"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
export function Quote1189e76f03d748c2Bdd89cd9f6b84bbd(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote1189e76f03d748c2Bdd89cd9f6b84bbd", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "186ebbf1-1d45-4ad7-ae4a-5d0f18d61901"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "e3d6b840-6be7-4347-b62c-bbcb2d4cb0c4"
export function Button186ebbf11d454ad7Ae4a5d0f18d61901(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button186ebbf11d454ad7Ae4a5d0f18d61901", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "445420f0-339b-4a69-85bf-3e1a77760bc5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7d2e6797-67b0-4282-9134-46f5f34a8c34"
export function Header445420f0339b4a6985bf3e1a77760bc5(props: any) {
  return (
    <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header445420f0339b4a6985bf3e1a77760bc5" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "7a54b720-6060-483b-b6c3-a1176b54d4f7"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader7a54b7206060483bB6c3A1176b54d4f7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader7a54b7206060483bB6c3A1176b54d4f7 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop02556be5784d4ee59ef204eff5d1753e" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroE3d6b8406be74347B62cBbcb2d4cb0c4 parentTag="HeroD5c011bf4095481a9407Eab3739a03f9" {...props} />}
    </div>
  );
}

// id: "635d2659-bfa4-4632-a922-15b87cc799ba"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "f989cb61-8862-44de-b7fe-a2206d508aa0"
export function LinkedLogo635d2659Bfa44632A92215b87cc799ba(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo635d2659Bfa44632A92215b87cc799ba", parentTag)} label={<DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="Label0905297a55c44ccaAb4598c886d14daf" alt="logo" />} {...props} />
  );
}

// id: "fc775835-179d-4145-a264-f30222cc308a"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "dbad0bbe-fe53-4b43-a173-e040f91e90c5"
export function SocialLinksFc775835179d4145A264F30222cc308a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksFc775835179d4145A264F30222cc308a flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "87faf390-fd4a-46e2-ba74-61c65be30503"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "f989cb61-8862-44de-b7fe-a2206d508aa0"
export const BackgroundImage87faf390Fd4a46e2Ba7461c65be30503 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "b2fffc85-b9ae-4cc4-8d0c-a364fe10e8ce"
// title: ""
// type: :text
// key: "title"
// parent_id: "94c2b264-d12e-46be-87ae-2b73911b6ff3"
export const TitleB2fffc85B9ae4cc48d0cA364fe10e8ce = "Secondary Marketing Title 2";

// id: "902d967b-e6e7-4b8f-bf92-9e553a5f402e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "8385fce0-7ad6-4f2d-9608-01fedcd186dc"
export const NavMenuSlug902d967bE6e74b8fBf929e553a5f402e = "members-primary-nav";

// id: "c388d4e7-0fbb-4cf1-bacc-4b2c5aea06bc"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Contact", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop5d2962a7Ca2242c0Ac5bE95f119ee2b7" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero1f041276F7a244198a11Bcf854c413db parentTag="Hero94c2b264D12e46be87ae2b73911b6ff3" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "a75a4214-e55e-46d3-aff1-fcc1cdc82389"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageA75a4214E55e46d3Aff1Fcc1cdc82389(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageA75a4214E55e46d3Aff1Fcc1cdc82389 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader7a54b7206060483bB6c3A1176b54d4f7 parentTag="Header4d314121Deca496eA664Bb8309f6ad59" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack64bb1d1047214747B8690df2957b6242", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyE2fd3081E1d74248927fF750a76c93a6 parentTag="Body55093b2a56de4cbb9df4A62074cd739c" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote1189e76f03d748c2Bdd89cd9f6b84bbd", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer1619de958c424ad8Ab0d831e2d6cfa02" {...props} />}
    </div>
  );
}

// id: "5075cffa-701f-4e4c-9cb2-e7bed235bc7b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4e3f7e91-f99d-4c6c-873d-f241fc668fd3"
export function Footer5075cffa701f4e4c9cb2E7bed235bc7b(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5075cffa701f4e4c9cb2E7bed235bc7b" {...props} />
  );
}

// id: "5d580719-a588-4d5d-b4b2-2954ed84d50e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0ae297aa-6672-4db0-95a6-ceb9375a5d62"
export function Footer5d580719A5884d5dB4b22954ed84d50e(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="Footer5d580719A5884d5dB4b22954ed84d50e" {...props} />
  );
}

// id: "5e392f94-4f16-4ee4-8338-b1a4c39ba0a0"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "6bf24987-0a43-43ff-b03d-887f6f6d17ff"
export const NavMenuSlug5e392f944f164ee48338B1a4c39ba0a0 = "marketing-primary-nav";

// id: "8385fce0-7ad6-4f2d-9608-01fedcd186dc"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c0b26a17-e9a0-4ad3-a77b-c4ffb6198b0d"
export function HeaderTop8385fce07ad64f2d960801fedcd186dc(props: any) {
  return (
    <DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf parentTag="HeaderTop8385fce07ad64f2d960801fedcd186dc" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "acac44c3-4b40-4c20-b4ab-6efb45219f6e"
// title: ""
// type: :text
// key: "alt"
// parent_id: "7f3fe285-67c3-4c10-9c38-f25c7e3d4059"
export const AltAcac44c34b404c20B4ab6efb45219f6e = "logo";

// id: "1b8a3dc4-d378-469d-adcc-39b73c3edf50"
// title: ""
// type: :html
// key: "body"
// parent_id: "0ae297aa-6672-4db0-95a6-ceb9375a5d62"
export function Body1b8a3dc4D378469dAdcc39b73c3edf50(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Body1b8a3dc4D378469dAdcc39b73c3edf50 about-main`}>
      <div className="logo">
        <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/about-bg.jpeg" alt="Night club photo" />
      </div>
      <div className="text">
        <p>
          Welcome to Mystique Entertainment! We are a premier event entertainment company specializing in hosting sophisticated experiences with genuine people at top clubs and venues. As industry experts, we thrive on creating unforgettable nightlife events that keep the party going.
        </p>
        <p>
          With a team of seasoned professionals, we curate a diverse lineup of entertainment, including renowned DJs, themed parties, and much more. Our events are carefully crafted to provide an immersive and energetic atmosphere, catering to music enthusiasts, partygoers, and thrill-seekers alike.
        </p>
        <p>
          Whether you&#39;re looking for a pulsating dance floor, exclusive VIP experiences, or a themed extravaganza, we&#39;ve got you covered. Our dedication to innovation and attention to detail ensures that each event is a one-of-a-kind celebration.   We take pride in our meticulous planning, creative flair, attention to detail and making all of our
          guest feel welcome.
        </p>
        <p>
          Join us for the ultimate nightlife experience, where we fuse cutting-edge production, top-tier talent, and a vibrant community to create memories that will keep you coming back for more. Explore our upcoming events and let us elevate your nightlife escapades to new heights!
        </p>
      </div>
    </main>
  );
}

// id: "26a2c507-a794-45bd-8173-2e56921d2b48"
// title: ""
// type: :text
// key: "class"
// parent_id: "6fba545b-826a-4fa9-a483-9890832b9ed1"
export const Class26a2c507A79445bd81732e56921d2b48 = "linked-logo";

// id: "b5a25a39-e8ee-4c60-9eeb-9a08c96ca8a1"
// title: ""
// type: :text
// key: "title"
// parent_id: "2b7acd42-7f87-4b83-a3cb-be724959a86e"
export const TitleB5a25a39E8ee4c609eeb9a08c96ca8a1 = "Join Us";

// id: "97f8937c-bbd8-40cd-8552-bbe21cc9dda1"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards97f8937cBbd840cd8552Bbe21cc9dda1(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards97f8937cBbd840cd8552Bbe21cc9dda1", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "e434e959-9eb6-45f4-b91c-ebd5e359d3f6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7d2e6797-67b0-4282-9134-46f5f34a8c34"
export function FooterE434e9599eb645f4B91cEbd5e359d3f6(props: any) {
  return (
    <DefaultFooterDbad0bbeFe534b43A173E040f91e90c5 parentTag="FooterE434e9599eb645f4B91cEbd5e359d3f6" {...props} />
  );
}

// id: "1827118a-e0d6-4a1c-b979-fc0909372a22"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7de9c0e3-aa2e-4b76-aef4-90cb181874c2"
export function Header1827118aE0d64a1cB979Fc0909372a22(props: any) {
  return (
    <MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d parentTag="Header1827118aE0d64a1cB979Fc0909372a22" {...props} />
  );
}

// id: "66b5c12a-0e57-45bd-8e10-2483ca6987ab"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0ae297aa-6672-4db0-95a6-ceb9375a5d62"
export function Header66b5c12a0e5745bd8e102483ca6987ab(props: any) {
  return (
    <SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc parentTag="Header66b5c12a0e5745bd8e102483ca6987ab" title="About Us" {...props} />
  );
}

// id: "d6eb7548-7e0f-408f-8af8-f3365029b12c"
// title: ""
// type: :text
// key: "title"
// parent_id: "c388d4e7-0fbb-4cf1-bacc-4b2c5aea06bc"
export const TitleD6eb75487e0f408f8af8F3365029b12c = "Contact";

// id: "a92e397a-f69b-4c7a-90d0-85f662fbfa5c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "076f4cff-bcb5-4f2e-b9fb-f7ff8d4889e2"
export const NavMenuSlugA92e397aF69b4c7a90d085f662fbfa5c = "members-primary-nav";

// id: "0905297a-55c4-4cca-ab45-98c886d14daf"
// title: ""
// type: :reference
// key: "label"
// parent_id: "635d2659-bfa4-4632-a922-15b87cc799ba"
export function Label0905297a55c44ccaAb4598c886d14daf(props: any) {
  return (
    <DefaultLogo7f3fe28567c34c109c38F25c7e3d4059 parentTag="Label0905297a55c44ccaAb4598c886d14daf" {...props} />
  );
}

const Components = {
  SecondaryPage12d1d2ae12ac480780deB881dd2002bc,
  MarketingBodyE2fd3081E1d74248927fF750a76c93a6,
  PublicEventsPageBc839488Be6b4767Aaba88e1894fcd5c,
  ShortHeaderF989cb61886244deB7feA2206d508aa0,
  Label61f0ea49A4014ef59545Ee7127b9a2c8,
  HeaderTop02556be5784d4ee59ef204eff5d1753e,
  Body55093b2a56de4cbb9df4A62074cd739c,
  AboutLevelingPageA86ad127F21b49d7A708F7257c716c99,
  JoinUsPage17b52c50Dc68459cB02bBb45d71edeb9,
  TitleFf9450b8886c485383a1Bdbfa4004782,
  Navigation9009853a498b42c7Ba822607088e4eaf,
  Footer5f3e0bcf69174a478193Fefe91ae2fb9,
  Title78ffbbc80e614910A4800542d4a5faa9,
  SocialLinks5f5fce32Aed34188983246c9f833e7ae,
  Title0ff046681889434b925a0ba649a3d2be,
  TitleF7e76a50584340979f892fcf3cb7d43e,
  AdminPages444dd43265c64a53A6dcFf399bcb8e60,
  AboutUsPage0ae297aa66724db095a6Ceb9375a5d62,
  MarketingCards48399032C0cd4370A1b7B6e8580e6f01,
  MembersAreaDefault7d2e679767b04282913446f5f34a8c34,
  MemberPages4e3f7e91F99d4c6c873dF241fc668fd3,
  DefaultHeaderTopF691343216f24da3A078D6f6b15fe0bf,
  Navigation076f4cffBcb54f2eB9fbF7ff8d4889e2,
  WelcomeBack64bb1d1047214747B8690df2957b6242,
  ButtonClass15a314d23c76467f9e03Ebd49694b066,
  Header156efa56311b4e40823a71d33cf9ddda,
  Footer903bdc283767416886fa0eaf56a0b4f8,
  NavMenuSlug1a5332daFa3d483bAe4843b7502a115f,
  Label752b4c1bD6e141f18851460ebe4c47ff,
  DefaultLogo7f3fe28567c34c109c38F25c7e3d4059,
  Href1dbf844376a8459eA9bdEed0b1e880aa,
  DefaultHeader6bf249870a4343ffB03d887f6f6d17ff,
  Footer4d6a773007c1404bBa2d46a9fcf81833,
  DefaultFooterDbad0bbeFe534b43A173E040f91e90c5,
  LinkedLogo6fba545b826a4fa9A4839890832b9ed1,
  PageQuoteC64eeb1840c5450cBadfDd9a73a0d143,
  SignupPagesFda27db5187b4540908b42004c9e9f47,
  SecondaryMarketingHero1f041276F7a244198a11Bcf854c413db,
  HeroD5c011bf4095481a9407Eab3739a03f9,
  Header2b7acd427f874b83A3cbBe724959a86e,
  FooterBaaf3cf17e284a80909c9f872e536896,
  Footer1fcb4a0902e8415cB7c48a1dea93f6e1,
  PostSlugEe5d2806E53947e0Ad729018d878788f,
  Header14322f0eB46f4fa2B977C1a4418e2d3d,
  Title8d7e119e7cc943399605D6c6fabc1a3f,
  ImageUrl674b01f76cc44fa9B128019b41352cc8,
  Hero94c2b264D12e46be87ae2b73911b6ff3,
  HeaderDb5f228269f34efb9783978cde84f0f3,
  Header130ab71d387447f9A31c40d9a3561481,
  NavMenuSlug51e11feeEc96498fB0709ee8e3300884,
  LabelFe3fc269Ee21447884ddC123bcc97518,
  Title6c89ed81955344ca80e0F52aaccb5b93,
  Title705086d94fbc4e54A241621dc14beec6,
  NavMenuSlug20d55058B9b04703843f20aaa7546293,
  TitleFb8eb8c9B7d749119411266eee43f7a2,
  MembersAreaHeaderC0b26a17E9a04ad3A77bC4ffb6198b0d,
  HeaderDf14a7fd56624b748a475000be1df259,
  HomePageHeroE3d6b8406be74347B62cBbcb2d4cb0c4,
  Header8ff2fc2259144ed4883d892703712b7d,
  Copyright42d5a40eD2944f729cceDc7a3d36da5c,
  Header4d314121Deca496eA664Bb8309f6ad59,
  HeaderTop5d2962a7Ca2242c0Ac5bE95f119ee2b7,
  Header0df4ae1880954c17B5928eab44704b24,
  Footer1619de958c424ad8Ab0d831e2d6cfa02,
  SecondaryNavAef9bd54Cdd94aa0B3feBc5134eb84ee,
  HeaderF6bc0e015c74487a842e51e80035ff07,
  HrefDbff28ca81534326A6c335b8f26cfcfa,
  Url6b9893a5D56d4777994cCc0d4ba27956,
  Title03e8bd21Cce0493a8d1484144b26f7bb,
  PostSlug8bcc28d6D01f4a96Be3211fcd92238e5,
  ResourcesPage6921be613d804b39982085d488320c24,
  MessagePages7de9c0e3Aa2e4b76Aef490cb181874c2,
  ContactA1d59f0195b84d75Ac3685918fe2ae8e,
  BackgroundImageC0ea1e7b2eca477bB8ceF585995e0549,
  LinksCf613b45F9b24fd89ea94d44ea00635f,
  GroupflowE6622f55131c4d37Abe24ce2e5c7672b,
  LogoB5fcef0625e94476B0baD5c429dd0673,
  ContentSlugB2c4b66032f64637Bf46Ad39c2977625,
  ClassCc00be694a5d4a17800f523efdfa1d5d,
  Quote1189e76f03d748c2Bdd89cd9f6b84bbd,
  Button186ebbf11d454ad7Ae4a5d0f18d61901,
  Header445420f0339b4a6985bf3e1a77760bc5,
  HomeHeader7a54b7206060483bB6c3A1176b54d4f7,
  LinkedLogo635d2659Bfa44632A92215b87cc799ba,
  SocialLinksFc775835179d4145A264F30222cc308a,
  BackgroundImage87faf390Fd4a46e2Ba7461c65be30503,
  TitleB2fffc85B9ae4cc48d0cA364fe10e8ce,
  NavMenuSlug902d967bE6e74b8fBf929e553a5f402e,
  SecondaryMarketingHeaderC388d4e70fbb4cf1Bacc4b2c5aea06bc,
  HomePageA75a4214E55e46d3Aff1Fcc1cdc82389,
  Footer5075cffa701f4e4c9cb2E7bed235bc7b,
  Footer5d580719A5884d5dB4b22954ed84d50e,
  NavMenuSlug5e392f944f164ee48338B1a4c39ba0a0,
  HeaderTop8385fce07ad64f2d960801fedcd186dc,
  AltAcac44c34b404c20B4ab6efb45219f6e,
  Body1b8a3dc4D378469dAdcc39b73c3edf50,
  Class26a2c507A79445bd81732e56921d2b48,
  TitleB5a25a39E8ee4c609eeb9a08c96ca8a1,
  MarketingCards97f8937cBbd840cd8552Bbe21cc9dda1,
  FooterE434e9599eb645f4B91cEbd5e359d3f6,
  Header1827118aE0d64a1cB979Fc0909372a22,
  Header66b5c12a0e5745bd8e102483ca6987ab,
  TitleD6eb75487e0f408f8af8F3365029b12c,
  NavMenuSlugA92e397aF69b4c7a90d085f662fbfa5c,
  Label0905297a55c44ccaAb4598c886d14daf
}

export default Components;