import "./index.css";
import Components from "./Components";
import ComponentsLookup from "./ComponentsLookup";
import AppStore from "./AppStore";
import ROUTES from "./routes";

function initializeWebSite(context: any) {
  Object.keys(context).forEach((key) => {
    AppStore[key] = context[key];
  });
}

const exports = {
  initializeWebSite,
  Components,
  ComponentsLookup,
  ROUTES,
};

const groupFlowExports: any = exports;
if (typeof window !== "undefined") {
  (window as any).groupFlowExports = groupFlowExports;
}
if (typeof global !== "undefined") {
  (global as any).groupFlowExports = groupFlowExports;
}
export default exports;
