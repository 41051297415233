const ROUTES = {
  "/": {
    "name": "HomePageA75a4214E55e46d3Aff1Fcc1cdc82389",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages444dd43265c64a53A6dcFf399bcb8e60",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage0ae297aa66724db095a6Ceb9375a5d62",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageA86ad127F21b49d7A708F7257c716c99",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/contact": {
    "name": "ContactA1d59f0195b84d75Ac3685918fe2ae8e",
    "type": "HTML",
    "key": "contact"
  },
  "/events/public": {
    "name": "PublicEventsPageBc839488Be6b4767Aaba88e1894fcd5c",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage17b52c50Dc68459cB02bBb45d71edeb9",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages4e3f7e91F99d4c6c873dF241fc668fd3",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages7de9c0e3Aa2e4b76Aef490cb181874c2",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage6921be613d804b39982085d488320c24",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesFda27db5187b4540908b42004c9e9f47",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;